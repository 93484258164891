import DeductCashFundAccordion from "../components/DeductCashFundAccordion"

const DeductCashFund = () => {
  return (
    <div>
      <h1>Cash Fund</h1>
      <DeductCashFundAccordion />
    </div>
  )
}

export default DeductCashFund
