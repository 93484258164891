import SalesAccordion from "../components/SalesAccordion"

const Transaction = () => {
  return (
    <div>
      <h1>Customer Sales Transaction</h1>
      <SalesAccordion />
    </div>
  )
}

export default Transaction
