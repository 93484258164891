import AddCashFundAccordion from "../components/AddCashFundAccordion"

const AddCashFund = () => {
  return (
    <div>
      <h1>Cash Fund</h1>
      <AddCashFundAccordion />
    </div>
  )
}

export default AddCashFund
