import SuppliesFabcon from "../components/SuppliesFabcon"



function SupplyFabcon() {
    return (
        <>
            <SuppliesFabcon />
        </>
    )
}

export default SupplyFabcon
