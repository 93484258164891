import ExpenseAccordion from "../components/ExpenseAccordion"

const ExpenseTransaction = () => {
  return (
    <div>
      <h1>Expense Transaction</h1>
      <ExpenseAccordion />
    </div>
  )
}

export default ExpenseTransaction
