import SuppliesDetergent from "../components/SuppliesDetergent"


function SupplyDetergent() {
    return (
        <>
            <SuppliesDetergent />
        </>
    )
}

export default SupplyDetergent
